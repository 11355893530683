<template>
  <form class="form" v-on:submit.prevent id="metaInfo">
    <div class="card card-custom card-stretch gutter-b bordered-card-primary">
      <div class="card-header border-0 py-5">
        <div class="card-title d-flex justify-content-between w-100">
          <div>
            <h6> 
              {{ $t("SETUP.META_INFO.MASTER_TAGS") }}
            </h6>
          </div>
        </div>
        <div class="w-75">
          <p>{{ $t("SETUP.META_INFO.META_INFO_DESCRIPTION") }}</p>
        </div>
      </div>

      <div class="card-body">
        <b-spinner
          v-if="loadingComplete == false && error.active == false"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <b-alert v-if="error.active" show variant="danger">
          {{ error.message }}
        </b-alert>
        <div v-if="loadingComplete" class="row d-flex justify-content-between px-5 py-2">
          <vue-tags-input
            id="tag-input"
            v-model="form.tag"
            :tags="form.tags"
            @before-adding-tag="postTag"
            @before-deleting-tag="deleteTag"
            @tags-changed="newTags => form.tags = newTags"
            placeholder="Input your project tags"
            class="form-control form-control-solid h-auto py-0 px-0 w-50"
            :separators="separators"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import VueTagsInput from  "@sergor5/vue-tags-input";
import {
  CLEAR_PROJECT_TAG_ERRORS,
  POPULATE_PROJECT_TAG_LIST,
  CREATE_PROJECT_TAG,
  DELETE_PROJECT_TAG
} from "@/core/services/store/project/project_tags.module";

export default {
  mixins: [validationMixin],  

  name: "MetaInfo",

  components: {
    VueTagsInput
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },

      error: {
        active: false,
        message: ""
      },

      submissionStates: {
        submitting: false
      },

      form: {
        tag: "",
        tags: []
      },

      separators: [';',',']
    };
  },

  mounted() {
    this.prepopulateTags();
  },

  methods: {
    prepopulateTags() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: "false"
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_TAG_LIST, payload)
        .then(() => {
          this.projectTagList.forEach(element => {
            this.form.tags.push({text: element.tag, id: element.id})
          });
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            `${this.$t("SETUP.META_INFO.LOAD_ERROR")}`;
        });
    },
    isDuplicate(tags, tag) {
      return tags.map(t => t.text).indexOf(tag) !== -1;
    },
    postTag(obj) {
      if (!this.isDuplicate(this.form.tags,obj.tag.text) && this.submissionStates.submitting == false) {
        this.submissionStates.submitting = true;

        let payload = {
          projectId: this.projectInfo.id,
          data: {
            tag: obj.tag.text
          }
        }

        this.$store.dispatch(CREATE_PROJECT_TAG, payload).then((res) => {
          obj.tag.id = res.id;
          obj.addTag();
          this.submissionStates.submitting = false;
        });
      };
    },
    deleteTag(obj) {
      if (this.submissionStates.submitting == false) {
        this.submissionStates.submitting = true;

        let payload = {
          projectId: this.projectInfo.id,
          id: obj.tag.id
        }

        this.$store.dispatch(DELETE_PROJECT_TAG, payload).then(() => {
          obj.deleteTag();
          this.makeToast("danger");
          this.submissionStates.submitting = false;
        });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_TAG_ERRORS);
    },
    makeToast(variant = null) {
      this.$bvToast.toast(`${this.$t("SETUP.META_INFO.TAG_REMOVED")}`, {
        title: `${this.$t("GENERAL.FEEDBACK.UPDATED")}`,
        variant: variant,
        solid: true
      })
    },
  },
  computed: {
    ...mapGetters(["clientInfo", "projectInfo", "projectTagList"]),

    isDisabled: function() {
      if (this.submissionStates.submitting == true) {
        return true;
      } else {
        return false;
      }
    },

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
